import React, { useCallback } from "react";
import { successToast } from "@novel/shared/utils/toastUtils";
import CopyClipBoard from "@novel/shared/icons/CopyClipBoard.png";
import { copyToClipboard } from "@novel/shared/utils/copyToClipboard";
interface CopyToClipBoardCmpProps {
  readonly stringToCopy?: string;
  readonly className?: string;
  readonly copyToastMessage?: string;
  readonly label?: string;
  readonly width?: string;
  readonly shiftToRight?: boolean;
}
export function CopyToClipBoardCmp({
  stringToCopy,
  shiftToRight,
  className,
  label,
  width,
  copyToastMessage = "copied to clipboard"
}: CopyToClipBoardCmpProps): React.JSX.Element {
  const copyStringToClipboard = useCallback(() => {
    if (stringToCopy) {
      copyToClipboard(stringToCopy).then(() => {
        successToast({
          content: copyToastMessage
        });
      });
    }
  }, [stringToCopy, copyToastMessage]);
  return <React.Fragment>
            <div className="text-field-style">
                <label className="label-wrap">{label ? label : "URL Handle"}</label>
                <input className="inputField" value={stringToCopy || ""} disabled autoComplete="off" />
                <div className={`clipboard ${className || ""}`.trim()} onClick={copyStringToClipboard}>
                    {!stringToCopy ? null :
        // handling next.js and storefront environments
        <img src={(CopyClipBoard as any)?.src || CopyClipBoard} onClick={copyStringToClipboard} />}
                </div>
            </div>
            <style global jsx>{`
                .inputField {
                    color: #000000;
                    -webkit-text-fill-color: #000000;
                    font-family: "Poppins";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 25px;
                    height: 40px;
                    text-transform: initial;
                    letter-spacing: initial;
                    position: relative;
                    z-index: 20;
                    display: block;
                    flex: 1 1;
                    width: 100%;
                    min-width: 0;
                    min-height: 3.6rem;
                    margin: 0;
                    padding: 0.5rem 1.2rem;
                    border: 1px solid #959595;
                    caret-color: #202223;
                    appearance: none;
                    padding-right: 40px;
                }
                .clipboard {
                    position: absolute;
                    right: 3px;
                    top: 50%;
                    transform: translateY(-50%);
                }
                .label-wrap {
                    position: absolute;
                    top: -8px;
                    left: 20px;
                    z-index: 100;
                    font-family: "Poppins";
                    font-style: normal;
                    font-weight: 400;
                    line-height: 16px;
                    color: #959595;
                    background: white;
                    padding-left: 10px;
                    padding-right: 10px;
                    font-size: 11px;
                }
                .text-field-style {
                    position: relative;
                    width: ${width ? width : "auto"};
                    ${shiftToRight && "margin-right: auto;"}
                }
            `}</style>
        </React.Fragment>;
}